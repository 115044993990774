
// Dependencies
import clsx from 'clsx';
import { RARIBLE_LINKS } from 'src/base/constants';

// App
import { Page } from 'ui/app';
import { ImageSection, RareLabs } from 'ui/sections';
import { Animation, Button, FlipCard, Image, Headline, CanvasImage, Logo, Link, Marquee } from 'ui/components';


//	Styles
import styles from './home-view.module.scss';
import { SHOW_SERUM_SECTION, SHOW_ZOMBIE_SECTION } from 'src/config';
import { getGreeting } from "../../../trpc/client"

import { useRef,  } from "react"
import dynamic from 'next/dynamic'

import ToadMapImage from "../../../../public/images/toadmap.webp"
import RaycBar from "../../../../public/images/rayc-bar.webp"
import LigthningBg from "../../../../public/images/lightning-bg.png"
import RareLabsImage from "../../../../public/images/rare-labs.webp"
import KntrvlrImage from "../../../../public/images/kntrvlr.webp"
import JiroImage from "../../../../public/images/jiro.webp"
import WeDiscover from "../../../../public/images/we-discover.webp"
import RareLabsSymbol from "../../../../public/images/rare-labs-symbol.webp"

//
//	RAYC / UI / Views / Home
//

// component dynamic decleartion
const Rak = dynamic(() => import('./components/rak'), { ssr: false });
const Merch = dynamic(() => import('./components/merch'), { ssr: false });
const Odyssey = dynamic(() => import('./components/odyssey'), { ssr: false });
const Serum = dynamic(() => import('./components/serum'), { ssr: false });
const Zombies = dynamic(() => import('./components/zombies'), { ssr: false });
const Lineage = dynamic(() => import('./components/lineage'), { ssr: false });
const Gallery = dynamic(() => import('./components/gallery'), { ssr: false });
const Gensis = dynamic(() => import('./components/genisis'), { ssr: false });




export default function HomeView() {
	const showSerumSection = SHOW_SERUM_SECTION;
	const showZombiesSection = SHOW_ZOMBIE_SECTION;


	const serumSectionRef = useRef(null);
	const zombiesSectionRef = useRef(null);


	const greet = async () => {
		const greetingMessage = await getGreeting()
		console.log('greetingMessage', greetingMessage)
	}


	const handleIntersection = (entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				switch (entry.target.id) {
					case 'serumSection':
						setIsSerumSectionVisible(true);
						break;
					case 'zombiesSection':
						setIsZombiesSectionVisible(true);
						break;
					// Add more cases for other sections
				}
			}
		});
	};


	return (
		<Page className={clsx(styles['v-home'], '-t-dark')}>


			<Rak />
			<Merch />
			<Odyssey />

			{/* 
			<section className={styles['v-home_odyssey']}>
				<Image
					className={styles['v-home_odyssey_img']}
					src="/images/Apepe_Odyssey_Logo.webp"
					width={860 * 0.8}
					height={200 * 0.8}
					alt="Apepe Odyssey Logo"
				/>

				<div className={styles['v-home_odyssey_text-content']}>
					<h4
						className={`${OdysseyCommonStyles['key-forge-header']} ${styles['v-home_odyssey_header']}`}
					>
						Collect lore pieces
					</h4>
					<p className={styles['v-home_odyssey_paragraph']}>Initially released as a meme, it has been discovered that there is more to Apepes than what meets the eye. The Apepe Odyssey is the chronicles of what we have learned so far from our journey to discover the truth about Apepes.</p>
				</div>
				<Button href="/odyssey/1"
					className={`${styles['v-home_odyssey_button']} -mxa`}
				>
					CLICK HERE
				</Button>
			</section> */}

			{showSerumSection && <Serum />}

			{showZombiesSection && <Zombies />}

			<Lineage />

			<Gallery />
			<Gensis/>

			<Marquee className={styles['v-home_rarelabs-marquee']}
				duration="400s">
				<div className="-va-center">
					<Image className={styles['v-home_rarelabs-marquee_img']}
						src={RareLabsSymbol}
						width={122}
						height={84}
						loading='eager'
						alt="" />
					<span className="f4 -f-caps -f-bold -f-family-burbank -color-base-110">Rare Apepes</span>
					<Image className={styles['v-home_rarelabs-marquee_img']}
						src={RareLabsSymbol}
						width={122}
						height={84}
						loading='eager'
						alt="" />
					<span className="f4 -f-caps -f-bold -f-family-burbank -color-base-110">Designed by Rare Labs</span>
					<Image className={styles['v-home_rarelabs-marquee_img']}
						src={RareLabsSymbol}
						width={122}
						height={84}
						loading='eager'
						alt="" />
					<span className="f4 -f-caps -f-bold -f-family-burbank -color-base-110">Join the Discord</span>
				</div>
			</Marquee>

			{/* Toadmap section  */}
			<section className={clsx('section', styles['v-home_map'])}
				id="s-toadmap">
				<div className={styles['v-home_map-bg']}>
					<Logo />
				</div>
				<div className={styles['v-home_map-content']}>
					<div className="grid -g-cols-1 -g-max-8 -a-center -va-c">
						<Headline type="h2"
							className="fhero -f-caps -mb0 -color-text-mid">
							<span className="-f-primary-gradient">Toad</span>
							Map
						</Headline>
						<p className="f5 -mt0">What’s in store for the community.</p>
					</div>
					<Image src={ToadMapImage}
						height={750}
						width={1500}
						loading='eager'
						alt="" />
				</div>
			</section>

			<section className={clsx(styles['v-home_join'], 'section -pt0')}
				style={{ marginTop: 30 }}>
				<div className={styles['v-home_join-container']}>
					<div className="grid -g-cols-1 -g-max-8 -a-center -va-c">
						<h4 className="f2 -f-family-chauncy -f-caps">Buy an Apepe to join the&nbsp;family...</h4>
						<Button href={RARIBLE_LINKS.GENESIS}
							external
							icon="rarible"
							iconPos="before">
							Buy an Apepe
						</Button>
					</div>
				</div>
			</section>

			<RareLabs id="s-artists" />

			<ImageSection src={RaycBar} />

			<section className={clsx('section', styles['v-home_team'])}
				id="s-team">
				<div className={styles['v-home_team-bg']}>
					<Image src={LigthningBg}
						width={3879}
						height={2635}
						alt="" />
				</div>
				<div className="grid -g-cols-1 -g-max-5 -a-center -va-c -mb6">
					<Headline type="h2"
						className="f1 -f-caps -style-primary-gradient">
						Founders
					</Headline>
					<p className="f5 -mt0">Two ingenious and talented indivuals who struck gold with the help of an amazing community whom we love.</p>
				</div>

				<div className="grid -g-cols-2 -s:g-cols-2 -g-max-6">
					<Animation.ScrollReveal className={clsx(styles['v-home_team-member'], '-ha-center')}>
						<Link className={styles['v-home_team-member_link']}
							href="https://twitter.com/Rare_labs"
							external>
							<Image className={clsx(styles['v-home_team-img'], '-mb3')}
								src={RareLabsImage}
								width={592}
								height={592}
								loading='eager'
								alt="" />
							<h3 className="f3 -f-family-chauncy -mb0">Rare Labs</h3>
							<p className="f7 -mt0">NFT Art Agency</p>
						</Link>
					</Animation.ScrollReveal>
					<Animation.ScrollReveal className={clsx(styles['v-home_team-member'], '-ha-center')}>
						<Link className={styles['v-home_team-member_link']}
							href="https://twitter.com/kountervailor"
							external>
							<Image className={clsx(styles['v-home_team-img'], '-mb3')}
								src={KntrvlrImage}
								width={592}
								height={592}
								loading='eager'
								alt="" />
							<h3 className="f3 -f-family-chauncy  -mb0">kntrvlr</h3>
							<p className="f7 -mt0">Blockchain Developer</p>
						</Link>
					</Animation.ScrollReveal>
				</div>

				<div className="grid -g-cols-1 -g-max-8 -a-center">
					<h4 className="f3 -color-primary -mt6 -mb4">Contributors</h4>
				</div>
				<div className="grid -g-cols-2 -s:g-cols-2 -g-max-6">
					<Animation.ScrollReveal className={clsx(styles['v-home_team-member'], '-ha-center')}>
						<Link className={styles['v-home_team-member_link']}
							href="https://twitter.com/jironft"
							external>
							<Image className={clsx(styles['v-home_team-img'], '-mb3')}
								src={JiroImage}
								width={592}
								height={592}
								loading='eager'
								alt="" />
							<h5 className="f3 -f-family-chauncy -mb0">Jiro</h5>
							<p className="f7 -mt0">Punk #9322, Bored Ape&nbsp;#3060</p>
						</Link>
					</Animation.ScrollReveal>
					<Animation.ScrollReveal className={clsx(styles['v-home_team-member'], '-ha-center')}>
						<Link className={styles['v-home_team-member_link']}
							href="https://wediscover.com.au/"
							external>
							<Image className={clsx(styles['v-home_team-img'], '-mb3')}
								src={WeDiscover}
								width={592}
								height={592}
								loading='eager'
								alt="" />
							<h5 className="f3 -f-family-chauncy  -mb0">We Discover</h5>
							<p className="f7 -mt0">Web 3.0 Design Agency</p>
						</Link>
					</Animation.ScrollReveal>
				</div>

				<div className="grid -g-cols-1 -g-max-8 -va-c">
					<Button href="https://discord.gg/FN3GZqJ9w9"
						external
						className="-mt6">
						Join our discord
					</Button>
				</div>
			</section>
		</Page>
	);
}
