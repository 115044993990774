import { ShaderMaterial, Color } from 'three';
import { extend } from '@react-three/fiber';


//
//	RAYC / UI / Sections / Hero / Headline Shader
//


class CustomMaterial extends ShaderMaterial {
	constructor() {
		super({
			vertexShader: `
				varying vec2 vUv;
				void main() {
					vUv = uv;
					gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.);
				}
			`,
			fragmentShader: `
				uniform sampler2D tex;
				uniform float hasTexture;
				uniform vec3 color;
				uniform float opacity;
				uniform float uTime;
				varying vec2 vUv;

				void main() {
					float time = uTime * 0.025;
					vec2 uv = fract(vUv * vec2(1.,1.) + vec2(-time, 0.));
					vec4 c = texture2D(tex, uv);
					if (hasTexture == 1.0) gl_FragColor = vec4(c.r, c.g, c.b, c.a * opacity);
					else gl_FragColor = vec4(color, opacity);
				}
			`,
			uniforms: {
				tex: { value: null },
				hasTexture: { value: 0 },
				uTime: { value: 0 },
				opacity: { value: 1 },
				color: { value: new Color( 'white' ) },
			},
		});
	}

	set uTime( value ) {
		this.uniforms.uTime.value = value;
	}

	set map( value ) {
		this.uniforms.hasTexture.value = !!value;
		this.uniforms.tex.value = value;
	}

	get map() {
		return this.uniforms.tex.value;
	}

	get opacity() {
		return this.uniforms.opacity.value;
	}

	set opacity( value ) {
		if ( this.uniforms ) this.uniforms.opacity.value = value;
	}
}

extend({ CustomMaterial });
