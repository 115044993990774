import React, { useEffect, useRef } from 'react';

// components
// import { Image } from 'src/ui/components';
import Card from './Card';
import ConnectWalletButton from './ConnectWalletButton';
import AlbumHeader from './AlbumHeader';
import { gsap } from 'gsap';
// hooks
import { useRouter } from 'next/router';
import useAlbumSetup from './useAlbumSetup';

// others
import styles from './Album.module.scss';
import { LORE_PIECE_STATUSES, SALE_STATUSES } from 'src/base/constants';
import LoadingOverlay from 'src/ui/components/LoadingOverlay';
import { useRecoilState } from 'recoil';
import addressAtom from 'src/state/addressAtom';
import { Image } from 'src/ui/components';



const publishedChapterIds = [1];

export default function OdysseyAlbumView() {
	const router = useRouter();
	const { chapterId } = router.query;
	const { connect, connected, showConnectWallet, items, loading, pieceInfos } = useAlbumSetup(chapterId);

	const [address, setAddress] = useRecoilState(addressAtom); // metamaskAddress

	const isConnected = address !== undefined && address !== null;

	useEffect(() => {
		if (chapterId && !publishedChapterIds.includes(Number(chapterId))) {
			alert('Not a valid chapter.');
			router.push('/');
		}
	}, [chapterId, router]);

	// TODO: fix globally
	const containerRef = useRef(null);
	useEffect(() => {
		console.log('containerRef.current', containerRef.current)
		if (items.length > 0) {
			gsap.to(window, {
				duration: 1,
				scrollTo: { y: '#album-items', offsetY: 0 },
			});
		}
		console.log('document.body.style.minHeight', document.body.style.minHeight)

		if (containerRef.current) {
			document.body.style.maxHeight = "unset";
			document.body.style.minHeight = `${containerRef.current.offsetHeight + 2050}px`;
			document.body.style.overflow = `auto`;
		}

	}, [containerRef, items]);

	function onCardClick(card) {
		if (card.status !== LORE_PIECE_STATUSES.PLACEHOLDER) {
			router.push({ pathname: `/odyssey/${chapterId}/${card.id}` });
		} else {
			return;
		}
	}


	return (
		<div
			className={`bg-cover bg-center bg-no-repeat h-auto flex flex-col items-center pb-[100px] pt-[10.75rem] max-[500px]:pb-[130px] ${styles['oddysey-album-wrapper']}`}>
			<Image
				className='mt-5'
				src="/images/Apepe_Odyssey_Logo.webp"
				width={860 * 0.8}
				height={200 * 0.8}
				alt="Apepe Odyssey Logosss"
			/>
			<AlbumHeader chapterId={chapterId} />
			<ConnectWalletButton showConnectWallet={showConnectWallet}
				connect={connect}
				connected={isConnected} />
			<div ref={containerRef}
				id='album-items'
				className={`grid-cols-3 gap-[19px] h-auto mt-[97px] w-[77.5%] max-w-[1200px] max-[1400px]:mt-[50px] max-[1200px]:w-[85%]  max-[1050px]:grid-cols-2  max-[800px]:w-[90%]  max-[550px]:grid-cols-1 max-[400px]:w-[95%] ${styles['oddysey-album_cards']}`}
				style={{ minHeight: 800 }}>
				{items.map((item) => {
					const pieceInfo = pieceInfos[item.id];

					let saleStatus;
					let quantityDisplay;

					if (pieceInfo) {
						const { tokenConfig } = pieceInfo;
						if (tokenConfig.startDate === 0) {
							saleStatus = SALE_STATUSES.NOT_STARTED;
						} else if (tokenConfig.startDate > Date.now() / 1000) {
							saleStatus = SALE_STATUSES.NOT_STARTED;
						} else if (tokenConfig.endDate < Date.now() / 1000) {
							saleStatus = SALE_STATUSES.ENDED;
						} else {
							saleStatus = SALE_STATUSES.ACTIVE;
						}
						if (saleStatus !== SALE_STATUSES.NOT_STARTED) {
							const limitStatement = tokenConfig.isLimited ? `/${tokenConfig.limit}` : '';
							quantityDisplay = `${pieceInfo.totalSupply}${limitStatement} Minted`;
						}

					}

					return <Card card={item}
						saleStatus={saleStatus}
						quantityDisplay={quantityDisplay}
						key={item.id}
						onCardClick={onCardClick}
						connected={isConnected} />;
				})}
			</div>
		</div>
	);
}
