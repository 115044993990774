
//	Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { animated as a, useTrail } from '@react-spring/web';
import _isArray from 'lodash-es/isArray';

// App
import { useIntersection }from 'src/hooks';

// Styles
import styles from './headline.module.scss';


//
//	RAYC / UI / Components / Headline
//


export default function Headline({ className, type, children, delayMultiple }) {
	const Comp = `${ type }`;
	const _children = _isArray( children ) ? children : [ children ];

	const headlineNodes = _children.reduce( ( acc, c, i ) => {
		if ( typeof c === 'string' ) acc.push( ...c.split( ' ' ) );
		if ( React.isValidElement( c ) ) acc.push( c );
		return acc;
	}, []);

	const [ ref, { isIntersecting }] = useIntersection({ threshold: 1 });

	const [ trail ] = useTrail( headlineNodes.length, {
		config: { mass: 0.5, tension: 250, friction: 30 },
		opacity: isIntersecting ? 1 : 0,
		y: isIntersecting ? 0 : 30,
		from: { opacity: 0, y: 30 },
		delay: delayMultiple ? delayMultiple * 350 : 0,
	}, [ isIntersecting ]);

	const headlineTrail = trail && trail.map( ({ y, opacity }, i ) => {
		const Content = headlineNodes[ i ];
		const nodeProps = {
			className: styles[ 'c-headline_text' ],
		};
		const isElement = React.isValidElement( Content );
		if ( isElement && Content.type === 'br' ) return Content;

		return (
			<a.div ref={ ref }
				key={ i }
				style={{ opacity }}
				{ ...nodeProps }>
				<a.div style={{ transform: y && y.to( y => `translate3d(0,${ y }px,0) rotateZ(${y * 0.1}deg)` ) }}>
					{ isElement ? Content : `${ Content }${ headlineNodes.length - 1 === i ? '' : ' ' }` }
				</a.div>
			</a.div>
		);
	});

	return (
		<Comp className={ clsx( styles[ 'c-headline' ], className )}>
			{ headlineTrail }
		</Comp>
	);
}

Headline.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	children: PropTypes.node,
	delayMultiple: PropTypes.number,
};

Headline.defaultProps = {
	type: 'h1',
};
