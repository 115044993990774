import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

//	App
import { useStore } from 'base/state';
import { Button, Image, Link, Logo, Marquee, Nav, Icon, LogoutButton } from 'ui/components';

// Styles
import styles from './header.module.scss';
import { SHOW_SERUM_SECTION, SHOW_ZOMBIE_SECTION, SHOW_APEPE_SECTION, SHOW_ODYSSEY, SHOW_CONNECT_WALLET, SHOW_KEY_FORGE } from 'src/config';

// Hooks
import { usePreviousRoute } from 'src/hooks/usePreviousRoute';
import { useRouteTracker } from 'src/hooks/useRouteTracker';
import useCheckDelegations from 'src/hooks/useCheckDelegations';
import useWallet from 'src/hooks/useWallet';

//	Others
import { SHOW_TOOL_TIP_X_TIMES } from 'src/config';
import { gsap } from 'gsap';
import clsx from 'clsx';
import { RARIBLE_LINKS, SOCIALS_LINKS } from 'src/base/constants';
import { navVariationsBuilder } from './headerItems';



//
//	RAYC / UI / Components / Header
//



// Note: this is for desktop
const Header = React.memo( function Header({ className, includeInPage, variation = 'default' }) {
	const api = useStore( s => s.api );
	const isMenuOpen = useStore( s => s.isMenuOpen );
	const [, address, disconnect] = useWallet(); // empty comma is deliberate, as we are skipping the first value in the array (connect);
	const { selectedVault } = useCheckDelegations( address );
	const [didRouteChange, routerPath] = useRouteTracker(); // route tracker
	const prevRoute = usePreviousRoute();



	const navVariations = navVariationsBuilder( api );

	// note: if 'address' has a value, that means the user is connected
	const [showConnectButton, setShowConnectButton] = useState( false );
	const enableWalletConnect = SHOW_CONNECT_WALLET;
	const timesShown = useStore( s => s.toolTipTimesShown );

	React.useEffect( () => {
		if ( routerPath !== prevRoute ) {
			if ( address && selectedVault === null && timesShown < SHOW_TOOL_TIP_X_TIMES ) {
				api.setToolTipShown( false );
			}
		}
	}, [didRouteChange]);

	useEffect( () => {
		setShowConnectButton( SHOW_CONNECT_WALLET && !address );
	}, [address]);

	const _handleNavClick = ( sectionKey ) => {
		gsap.to( window, { duration: 1, scrollTo: { y: `#${sectionKey}`, offsetY: 0 }});
	};

	const showSerum = false && SHOW_SERUM_SECTION;
	const showZombie = SHOW_ZOMBIE_SECTION;
	const showApepe = SHOW_APEPE_SECTION;
	const showApepeOdyssey = SHOW_ODYSSEY;
	const showKeyForge = SHOW_KEY_FORGE;
	// no using this not only for home but for everything so can set for true now and add conditions that sets to false on need
	const showMenu = true;



	const navItems = navVariations[`${variation}`].navItems || navVariations.default.navItems;
	const connectWalletVariation = navVariations[`${variation}`].connectWalletButton || navVariations.default.connectWalletButton;

	const ConnectButton = () => connectWalletVariation;

	const headerDefaultClass = styles['a-header'];
	const headerClasses = [
		headerDefaultClass,
		includeInPage ? styles['a-header_push-down'] : '',
		includeInPage ? 'relative bg-[rgba(0,0,0,.5] h-auto z-1' : '',

	];

	return (
		<header className={`${clsx([...headerClasses], className )} z-[2]`}>
			<Link href="https://merch.rareapepes.com">
				<Marquee size="s"
					className={clsx( styles['a-header_marquee'], '-t-brand' )}>
					<Image className={styles['a-header_marquee-img']}
						src="/images/toxic-warning.png"
						width={36}
						height={36}
						alt="" />
					<span className="-f-caps -f-bold -f-family-burbank">Warning: the Apepe Swag is upon us</span>
				</Marquee>
			</Link>
			<div className={`w-full my-0 mx-auto flex justify-center flex-wrap box-border ${styles['a-header_container']}`}>
				<div className={clsx( styles['a-header_bar'])}>
					<div className={`relative flex items-center ${styles['a-header_logo-container']}`}>
						<Link ariaLabel="Home"
							href="/"
							className={clsx( styles['a-header_logo'], '-style-text' )}>
							<Logo className={styles['a-header_logo']} />
						</Link>
					</div>
					{showMenu && (
						<Nav className={clsx( styles['a-header_nav'], '-f-family-burbank flex wrap items-center' )}>
							{navItems &&
								navItems?.map( ( navItem ) => <Nav.Item
									key={navItem?.key}
									onClick={() => _handleNavClick( navItem?.key )}
									className={clsx(
										'f5 -style-headline -hide-s',
										styles['a-header_nav-item'],
									)}
								>
									{navItem?.element}
								</Nav.Item> )}
						</Nav>
					)}

					<div className={`ml-auto flex wrap items-center ${styles['a-header_socials']}`}>
						<div className={`flex wrap items-center mt-5 mr-5 ${styles['a-header_serum-zombie']}`}>

						</div>

						<Link ariaLabel="discord"
							href="https://discord.gg/FN3GZqJ9w9"
							className="-style-headline -hide-s"
							external>
							<Icon type="discord" />
						</Link>
						<Link ariaLabel="rarible"
							href={RARIBLE_LINKS.GENESIS}
							className="-style-headline -hide-s"
							external>
							<Icon type="rarible" />
						</Link>
						<Link ariaLabel="twitter"
							href={SOCIALS_LINKS.TWITTER}
							className="-style-headline -hide-s"
							external>
							<Icon type="twitter" />
						</Link>
						{enableWalletConnect && ( showConnectButton ?
							ConnectButton()
							: <LogoutButton className={clsx( '-mxa -hide-s', 'w-[240px] h-[58px] text-lg' )}
								disconnectWallet={disconnect}
								address={address} /> ) // Add the address display here
						}
					</div>

					<div className="-show-s -mla">
						<Button
							className={clsx( '-style-none', styles['a-header_menu-button'])}
							icon={isMenuOpen ? 'cross' : 'menu'}
							ariaLabel={isMenuOpen ? 'Close menu' : 'Open menu'}
							onClick={() => api.toggleMenuView()}
						/>
					</div>
				</div>
			</div>
		</header>
	);
});

Header.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

export default Header;
