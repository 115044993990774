
//
//	RAYC / Base / Utils
//

import { TEAM_ADDRESSES } from 'src/config';
import { CONTRACT_NAMES, KEYMASTERS_POT_ADDRESS_PROD, KEYMASTERS_POT_ADDRESS_TEST, LOOT_MAIN_ADDRESS_PROD, LOOT_MAIN_ADDRESS_TEST, LOOT_MINTER_ADDRESS_PROD, LOOT_MINTER_ADDRESS_TEST, NETWORKS, SUPPORTED_NETWORKS, LOOT_MINTER_2_ADDRESS_TEST, LOOT_MINTER_2_ADDRESS_PROD, LOOT_MINTER_3_ADDRESS_PROD } from './constants';
import { RAYC_ADDRESS_PROD, ZAYC_ADDRESS_PROD, RAYC_ADDRESS_TEST, ZAYC_ADDRESS_TEST, LORE_ADDRESS_TEST, LORE_ADDRESS_PROD, KEY_ADDRESS_PROD, KEY_ADDRESS_TEST, LOOT_MINTER_3_ADDRESS_TEST } from './constants';
import { ethers } from 'ethers';
import { Address } from './types';

export function isServer() {
	return typeof window === 'undefined';
}

export function isClient() {
	return !isServer();
}

export function weiToEther( wei: ethers.BigNumberish ) {
	return ethers.utils.formatEther( wei );
}

export function evaluateFeatureFlag( globalFeatureFlag: boolean, address: Address ) {
	if ( !address ) {
		return globalFeatureFlag;
	}


	const isTeamAddress = TEAM_ADDRESSES.map( x => x.toLowerCase() ).includes( address.toLowerCase() );

	return globalFeatureFlag || isTeamAddress;

}

export function chainIdToName( chainId: number ) {
	switch ( chainId ) {
		case NETWORKS.ETH.chainId:
			return NETWORKS.ETH.name;
		case NETWORKS.MUMBAI.chainId:
			return NETWORKS.MUMBAI.name;
		default:
			console.error( 'Unsuported chainId: ', chainId );
			return 'Unknown';
	}
}

export function getContractAddresses( chainName: string ) {
	if ( chainName === null ) {
		return ({
			raycAddress: null,
			zaycAddress: null,
		});
	}
	switch ( chainName ) {
		case NETWORKS.ETH.name:
			return ({
				raycAddress: RAYC_ADDRESS_PROD,
				zaycAddress: ZAYC_ADDRESS_PROD,
				loreAddress: LORE_ADDRESS_PROD,
				keyAddress: KEY_ADDRESS_PROD,
				lootAddress: LOOT_MAIN_ADDRESS_PROD,
				lootMinterAddress: LOOT_MINTER_ADDRESS_PROD,
				lootMinter2Address: LOOT_MINTER_2_ADDRESS_PROD,
				lootMinter3Address: LOOT_MINTER_3_ADDRESS_PROD,
				lootMainAddress: LOOT_MAIN_ADDRESS_TEST,
				keymastersPotAddress: KEYMASTERS_POT_ADDRESS_PROD,
			});
		case NETWORKS.MUMBAI.name:
			return ({
				raycAddress: RAYC_ADDRESS_TEST,
				zaycAddress: ZAYC_ADDRESS_TEST,
				loreAddress: LORE_ADDRESS_TEST,
				keyAddress: KEY_ADDRESS_TEST,
				lootAddress: LOOT_MAIN_ADDRESS_TEST,
				lootMinterAddress: LOOT_MINTER_ADDRESS_TEST,
				lootMinter2Address: LOOT_MINTER_2_ADDRESS_TEST,
				lootMinter3Address: LOOT_MINTER_3_ADDRESS_TEST,
				lootMainAddress: LOOT_MAIN_ADDRESS_TEST,
				keymastersPotAddress: KEYMASTERS_POT_ADDRESS_TEST,
			});
		default:
			console.log( 'Unsupported chainName: ', chainName );
			return {};
	}
}

// get contract abi based on contract name
export function getContractAbi( contractName: string ) { // TODO: update type to be typeof CONTACT_NAMES[string]
	switch ( contractName ) {
		case CONTRACT_NAMES.LORE:
			return require( '../contracts/abis/lore.json' );
		case CONTRACT_NAMES.RAYC:
		case CONTRACT_NAMES.ZAYC:
			return require( '../contracts/abis/erc721.json' );
		case CONTRACT_NAMES.KEY:
			return require( '../contracts/abis/key.json' );
		case CONTRACT_NAMES.KEYMASTERS_POT:
			return require( '../contracts/abis/keymastersPot.json' );
		case CONTRACT_NAMES.LOOT:
			return require( '../contracts/abis/loot.json' );
		case CONTRACT_NAMES.LOOT_MINTER:
			return require( '../contracts/abis/lootMinter.json' );
		case CONTRACT_NAMES.LOOT_MINTER_2:
			return require( '../contracts/abis/lootMinter2.json' );
		case CONTRACT_NAMES.LOOT_MINTER_3:
			return require( '../contracts/abis/lootMinter3.json' );
		case CONTRACT_NAMES.LOOT_MAIN:
			return require( '../contracts/abis/lootMain.json' );
		default:
			console.error( 'getContractAbi: Unsupported contractName: ', contractName );
	}
}

export function formatDate( unixTimestamp: number ) {
	if ( unixTimestamp === null || unixTimestamp === undefined ) {
		return null;
	}
	// Create a Date object from the Unix timestamp
	const date = new Date( unixTimestamp * 1000 );

	// Array of month names
	const monthNames = [
		'January', 'February', 'March', 'April', 'May', 'June',
		'July', 'August', 'September', 'October', 'November', 'December',
	];

	// Format the date string
	const formattedDate = `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;

	// Format the time string using user's local timezone
	const formattedTime = new Intl.DateTimeFormat( 'en-US', {
		hour: '2-digit',
		minute: '2-digit',
		timeZoneName: 'short',
	}).format( date );

	// Combine formatted date and time
	const formattedDateTime = `${formattedDate} at ${formattedTime}`;

	return formattedDateTime;
}

export function abbreviateAddress( targetAddress: Address ) {
	if ( targetAddress === null || targetAddress === undefined ) {
		return null;
	}
	return `${targetAddress.substring( 0, 3 )
	}..${targetAddress.substring( targetAddress.length - 4, targetAddress.length )}`;
}

export function isSupportedNetworkName( networkName: "ethereum" | "mumbai" ) {
	return SUPPORTED_NETWORKS.map( x => x.name ).includes( networkName );
}

export function hostnameIsProd( hostname: string ) {
	return hostname.includes( 'apepes.com' );
}
