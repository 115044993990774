
import { Button, Image, Link, ActionButton, LogoutButton } from 'ui/components';
import styles from './menu.module.scss';
import clsx from 'clsx';
import { SHOW_TERMS, SHOW_LICENSE } from 'src/config';

const MobileNavLink = ({ href, display }) => <Link
	href={href}
	className={`-f-family-burbank uppercase text-2xl my-2.5 ${styles['a-menu_link']}`}
>
	{display}
</Link>;

const mobileMenuItems = [
	{
		key: 's-home',
		show: true,
		element: <MobileNavLink href="home"
			display="Home" />,
	},
	{
		key: 's-dashboard',
		show: true,
		element: <MobileNavLink href="dashboard"
			display="Dashboard" />,
	},
	{
		key: 's-terms',
		show: SHOW_TERMS,
		element: (
			<MobileNavLink href="/terms"
				display="Terms" />
		),
	},
	{
		key: 's-license',
		show: SHOW_LICENSE,
		element: (
			<MobileNavLink href="/nft-license"
				display="NFT License" />
		),
	},	
];

export default function MenuItems({ variation, api, showConnectButton, disconnect, address }) {
	const MenuVariations = {
		default: {
			menuItems: mobileMenuItems,
			connectWalletButton: (
				<Button
					external
					icon="fox"
					iconPos="before"
					className={clsx( '-mxa', 'mt-2.5' )}
					onClick={() => {
						api.setIsModalOpen( true );
						api.toggleMenuView();
					}}
				>
                    Connect Wallet
				</Button>
			),
		},
		dashboardPublished: {
			menuItems: mobileMenuItems,
			connectWalletButton: (
				<Button
					external
					icon="fox"
					iconPos="before"
					className={clsx( '-mxa', styles['a-menu_btn'])}
					onClick={() => {
						api.setIsModalOpen( true );
						api.toggleMenuView();
					}}
				>
                    Connect Wallet
				</Button>
			),

		},
		dashboard: {
			menuItems: mobileMenuItems,
			connectWalletButton: (
				<ActionButton
					textContent="connect wallet"
					onClick={() => {
						api.setIsModalOpen( true );
						api.toggleMenuView();
					}}
				/>
			),
		},
	};

	const menuItems =
    MenuVariations[`${variation}`].menuItems ||
    MenuVariations.default.menuItems;
	const connectWalletVariation = MenuVariations[`${variation}`].connectWalletButton || MenuVariations.default.connectWalletButton;
	const ConnectButton = () => connectWalletVariation;


	return <div className={`flex flex-col items-center ${styles['a-menu_nav']}`}>
		{menuItems &&
            menuItems?.map( ( navItem, index ) => {
            	if ( navItem.show === true ) {
            		return <span key={index}
            			onClick={() => {
            			api.toggleMenuView();
            		}}> 
            			{' '}
            			{navItem.element}
            		</span>;
            	}
            })}
		{
			showConnectButton ? (
				ConnectButton()
			) : (
				<LogoutButton
					onClick={disconnect}
					address={address}
					disconnectWallet={disconnect}
				/>
			)
			// Insert the wallet address here
		}
	</div>;

}
