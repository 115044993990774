import styles from './dashboard-view.module.scss';
import clsx from 'clsx';
import FolderIcon from 'src/icons/FolderIcon';
import TestTubeIcon from 'src/icons/TestTubeIcon';
import LinkIcon from 'src/icons/LinkIcon';
import DashboardLayoutIcon from 'src/icons/DashboardLayoutIcon';
import FactoryIcon from 'src/icons/FactoryIcon';
import ToolsIcon from 'src/icons/ToolsIcon';


// internal component
import ConnectBar from './ConnectBar/ConnectBar.tsx';
import { Link } from 'src/ui/components';
import CouncilIcon from 'src/icons/Council';

// connect, address, connected, connectWallet, provider,

export default function DashboardView({ selectedTab, tabs, connected, connectWallet }) {
	return (
		<div className={styles.dashboard}>
			<div className={styles['dashboard-tabs']}>
				{tabs?.map( ( tab, index ) => {
					const isSelectedTab = tab.text === selectedTab?.text;
					return (
						<Link
							key={index}
							className={`${isSelectedTab ? styles['dashboard-tabs-item-selected'] : ''
							} ${styles['dashboard-tabs-item']}`}
							href={`/dashboard/${tab.slug}`}
						>
							<div className={styles['dashboard-tabs-item-icon']}>
								{tab.icon}
							</div>
							<div className={styles['dashboard-tabs-item-glow']} />

							<div
								className={clsx(
									'-f-family-burbank',
									styles['dashboard-tabs-item-text'],
								)}
							>
								{tab.text}
							</div>
						</Link>
					);
				})}
			</div>
			<div className={styles['dashboard-tab-content']}>
				{connected || !selectedTab?.requireWalletConnection ? selectedTab?.content : <ConnectBar onConnectWallet={connectWallet} />}
			</div>
		</div>
	);
}

DashboardView.defaultProps = {
	// this tabs will be used to define list of tabs including text icon and slug
	// however tab navigation will occur at pages/dashboard/(filename||tabslug).js
	tabs: [
		{
			slug: '',
			text: 'Home',
			icon: <DashboardLayoutIcon />,
		},
		{
			slug: 'your-collection',
			text: 'Your Collection',
			icon: <FolderIcon />,
		},
		// {
		// 	text: 'Community projects',
		// 	icon: <TestTubeIcon />,
		// 	content: <CommunityProjects />,
		// 	requireWalletConnection: false,
		// },
		{
			slug: 'meme-generator',
			text: 'Meme Generator',
			icon: <FactoryIcon />,
		},
		{
			slug: 'meet-council',
			text: 'Meet the council',
			icon: <CouncilIcon />,
		},
		{
			slug: 'community-tools',
			text: 'Community tools',
			icon: <ToolsIcon />,
		},
		{
			slug: 'useful-links',
			text: 'useful links',
			icon: <LinkIcon />,
		},
	],
};
