import { NULL_ADDRESS } from 'src/base/constants';
import { ethers } from 'ethers';

export default function useMintingFunctions({ pieceId, mintCount, setShowLoadingOverLay, setCustomLoadingMessage, priceToUse, raycOrZaycAddress, selectedVault, tokenIdToUse, tokenIdsToUse, tierToUse, loreContract }) {
	async function mintSingle() {
		// this assumes the wallet is already connected
		const msgValueWei = priceToUse && ethers.parseEther( priceToUse );
		// pieceId is the tokenId of the piece
		const tokenAddress = raycOrZaycAddress; // the 'mint pass address';
		const tokenId = tokenIdToUse;
		// if the token address is null, then don't pass the vault address even if there is a selected vault
		const vaultAddress = ( selectedVault && tokenAddress !== NULL_ADDRESS ) ? selectedVault : NULL_ADDRESS;

		try {
			setCustomLoadingMessage( 'Awaiting transaction approval' );
			setShowLoadingOverLay( true );

			const tx = await loreContract.mint( pieceId, tokenAddress, tokenId, vaultAddress, { value: msgValueWei });

			setCustomLoadingMessage( 'Confirming transaction' );
			await tx.wait();
			alert( 'Mint successful!' );
			location.reload();
		} catch ( e ) {
			if ( e.reason && e.reason.includes( 'Invalid delegate-vault pairing' ) ) {
				alert( `Error: Invalid delegate-vault pairing. This means your hot wallet is not a delegate for the ${tierToUse} in your cold wallet (vault address)` );
			} else {
				console.error( e );
			}
		} finally {
			setShowLoadingOverLay( false );
		}
	}
	async function mintMultiple() {
		const msgValueWei = priceToUse && ethers.parseEther( priceToUse ) * mintCount ;
		// TODO: handle a mixture
		const ids = Array.from({ length: mintCount }, () => pieceId );
		const tokenAddresses = Array.from({ length: mintCount }, () => raycOrZaycAddress ); // the 'mint pass address';
		const tokenIds = tierToUse === 'public' ? Array.from({ length: mintCount }, () => 0 ) : tokenIdsToUse.slice( 0, mintCount );
		const vaults = Array.from({ length: mintCount }, () => ( selectedVault && raycOrZaycAddress !== NULL_ADDRESS ) ? selectedVault : NULL_ADDRESS );

		try {
			setCustomLoadingMessage( 'Awaiting transaction approval' );
			setShowLoadingOverLay( true );

			const tx = await loreContract.mintMultiple( ids, tokenAddresses, tokenIds, vaults, { value: msgValueWei });

			setCustomLoadingMessage( 'Confirming transaction' );
			await tx.wait();
			alert( 'Mint successful!' );
			location.reload();
		} catch ( e ) {
			if ( e.reason && e.reason.includes( 'Invalid delegate-vault pairing' ) ) {
				alert( `Error: Invalid delegate-vault pairing. This means your hot wallet is not a delegate for the ${tierToUse} in your cold wallet (vault address)` );
			} else {
				console.error( e );
			}
		} finally {
			setShowLoadingOverLay( false );
		}
	}

	async function onClickMint () {
		if ( mintCount === 0 ) return;
		if ( mintCount === 1 ) {
			await mintSingle();
		} else {
			await mintMultiple();
		}
	}

	return ({ onClickMint });
}
