//	Dependencies
import React, { useEffect, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { useContextBridge, useDetectGPU, useProgress } from '@react-three/drei';
import _debounce from 'lodash-es/debounce';
import { gsap } from 'gsap';
import clsx from 'clsx';

//	App
import { useStore } from 'base/state';
import { Footer, Header, Menu } from 'ui/app';
import { useIsMobile } from 'src/hooks';
import { NoSSR } from 'ui/components';
import { LoadingView } from 'ui/views';
import useScrollSpring, { ScrollSpringContext, ScrollSpringContextProvider } from 'src/hooks/use-scroll-spring';

// Styles
import styles from './layout.module.scss';
import ToolTip from 'src/ui/components/tooltip/tooltip';
import { Modal } from 'src/ui/components';


//
//	RAYC / UI / App / Layout
//


const Layout = React.memo( function _Layout({ className, children, canvasChildren, footerVariation, includeInPage, navigationVariation, includeFooterInPage }) {
	const api = useStore( s => s.api );
	return (
		<div className={clsx( 'a-layout', styles['a-layout'], className )}>
			<ScrollSpringContextProvider>
				<Main canvasChildren={canvasChildren}>
					<ToolTip pushDown={includeInPage} />
					<Modal />
					<Header includeInPage={includeInPage}
						variation={navigationVariation} />
					<Menu onClose={() => api.closeMenuView()}
						variation={navigationVariation} />
					{children && children}
					<Footer variation={footerVariation}
						includeInPage={includeInPage || includeFooterInPage} />
				</Main>
			</ScrollSpringContextProvider>
			<NoSSR>
				<LoadingView />
				<BodyHeightSentinel />
				<LoaderSentinel />
			</NoSSR>
		</div>
	);
});

export default Layout;

const TOTAL_LOADABLE_ITEMS = 13; // Set this manually

function LoaderSentinel() {
	const api = useStore( s => s.api );
	const { loaded, total } = useProgress();

	useEffect( () => {
		if ( ( loaded !== TOTAL_LOADABLE_ITEMS ) && total > 0 ) return;
		setTimeout( () => api.setIsLoaded( true ), 100 );
	}, [loaded, api, total]);
}

function BodyHeightSentinel() {
	const bodyHeight = useStore( s => s.bodyHeight );

	useEffect( () => {
		const isForcedHeight = !( typeof bodyHeight !== 'number' );
		const elHeight = isForcedHeight ? bodyHeight : 'auto';
		document.body.style.height = `${elHeight}px`;
	}, [bodyHeight]);

}


const Main = React.memo( function _Main({ children, canvasChildren, className }) {
	const api = useStore( s => s.api );
	const isLoaded = useStore( s => s.isLoaded );
	const isMobile = useIsMobile();
	const cachedViewportHeight = useRef( 0 );
	const [, { setScrollNode, setMainNode, mainNode, scrollNode }] = useScrollSpring();

	useEffect( () => {
		const _resizeHandler = _debounce( () => {
			const _wW = window?.innerWidth;
			const _wH = scrollNode?.offsetHeight;
			const _bH = mainNode?.scrollHeight;

			if ( typeof _wW !== 'number' || typeof _wH !== 'number' || typeof _bH !== 'number' ) return;
			if ( isMobile && cachedViewportHeight.current === _wH ) return;
			const viewport = [_wW, _wH];
			api.setViewport( viewport, _bH );
			cachedViewportHeight.current = _wH;
		}, 100 );

		_resizeHandler();

		window.addEventListener( 'resize', _resizeHandler, { passive: true });
		return () => window.removeEventListener( 'resize', _resizeHandler );
	}, [api, mainNode, scrollNode, isMobile]);

	return (
		<div className={clsx( 'scroll-container', { '-is-loaded': isLoaded })}
			ref={$el => setScrollNode( $el )}>
			<main
				id="main-content"
				className={clsx( styles['a-layout_main'], className )}
				ref={el => {
					if ( !el ) return;
					setMainNode( el );
					api.setViewport( null, el.clientHeight ); // before Jul 2023, this was scrollHeight rather than clientHeight
				}}
			>
				{children && children}
			</main>
			<MainCanvas mainNode={mainNode}
				canvasChildren={canvasChildren} />
		</div>
	);
});


const MainCanvas = React.memo( function _MainCanvas({ mainNode, canvasChildren }) {
	const containerRef = useRef();
	const ContextBridge = useContextBridge( ScrollSpringContext );

	const GPUTier = useDetectGPU();
	let maxDpr = 1;
	if ( GPUTier.isMobile ) maxDpr = window?.devicePixelRatio === 1 ? 1 : 2;

	return (
		<div key="main-canvas"
			ref={containerRef}
			className={styles['a-layout_canvas']}>
			<Canvas
				linear
				orthographic
				camera={{
					zoom: 75,
					position: [0, 0, 500],
					near: 50,
					far: 750,
				}}
				key="canvas"
				gl={{ antialias: false }}
				dpr={[1, maxDpr]}
				onCreated={state => {
					state.events.connect( mainNode );
				}}
			>
				<ContextBridge>
					<ScrollContainer>
						{canvasChildren && canvasChildren}
					</ScrollContainer>
				</ContextBridge>
			</Canvas>
		</div>
	);
});


const ScrollContainer = React.memo( function _ScrollContainer({ children }) {
	const [, { getScrollY }] = useScrollSpring();
	const scrollGroupRef = useRef();

	useEffect( () => {
		gsap.ticker.add( () => {
			if ( !scrollGroupRef.current ) return;
			scrollGroupRef.current.position.y = getScrollY() / 75;
		});
	}, [scrollGroupRef, getScrollY]);

	return (
		<group ref={scrollGroupRef}>
			{children && children}
		</group>
	);
});
