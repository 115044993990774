import axios from 'axios';
import { API_BASE_URI_DEV, API_BASE_URI_LOCAL, API_BASE_URI_PROD } from 'src/base/constants';
import { NETWORKS } from 'src/base/constants';
import { isSupportedNetworkName } from 'src/base/utils';

export async function getLorePieceInfo( networkName, chapterId, pieceId ) {
	if ( networkName === undefined || !chapterId || !pieceId ) {
		return null;
	}
	networkName = networkName ?? NETWORKS.ETH.name; 

	// TODO: make dry
	if ( !isSupportedNetworkName( networkName ) ) {
		console.error( 'getLorePieceInfo: Unsupported networkName: ', networkName );
		return null;
	}

	let baseUri = networkName === 'Ethereum' ? API_BASE_URI_PROD : API_BASE_URI_DEV;
	if ( window?.location?.hostname === 'localhost' ) {
		baseUri = API_BASE_URI_LOCAL;
		networkName = NETWORKS.MUMBAI.name;
	}

	const url = `${baseUri}/odyssey/piece-info?chapterId=${chapterId}&pieceId=${pieceId}&network=${networkName.toLowerCase()}`;

	try { 

		const response = await axios.get( url );
		
		return response.data;
	} catch ( error ) {
		console.error( 'getLorePieceInfo: ', error );
		return null;
	}
}
