import { shaderMaterial } from '@react-three/drei';
import { extend } from '@react-three/fiber';

const LayerMaterial = shaderMaterial(
	{
		map: null,
		wiggle: 0,
		parallax: 0,
		opacity: 1,
		time: 0,

		uImageScale: [ 1, 1 ],
		uImageRes: [ 1, 1 ],
		uMeshScale: [ 1, 1 ],
		uOffset: [ 0, 0 ],
	},
	`
	uniform float time;
	uniform float wiggle;
	uniform float parallax;
	varying vec2 vUv;
	varying vec3 vNormal;
	uniform vec2 uOffset;
	void main()	{
		vUv = uv;
		vec3 transformed = vec3( position );
		if ( wiggle > 0. ) {
			float theta = sin( time + position.y ) / 2.0 * wiggle;
			float c = cos( theta );
			float s = sin( theta );
			mat3 m = mat3( c, 0, s, 0, 1, 0, -s, 0, c );
			transformed = transformed * m;
			vNormal = vNormal * m;
		}
		if ( parallax != 0. ) {
			transformed = vec3( transformed.x, transformed.y + uOffset.y * parallax, transformed.z );
		}
		gl_Position = projectionMatrix * modelViewMatrix * vec4( transformed, 1. );
	}`,
	`
	uniform float time;
	uniform float parallax;
	uniform float opacity;
	uniform sampler2D map;
	uniform vec2 uImageRes;
	uniform vec2 uMeshScale;
	uniform vec2 uOffset;
	uniform vec2 uImageScale;
	varying vec2 vUv;

	vec2 preserveAspectRatioSlice( vec2 uv, vec2 planeSize, vec2 imageSize ){
		vec2 ratio = vec2(
			min(( planeSize.x / planeSize.y ) / ( imageSize.x / imageSize.y ), 1.0 ),
			min(( planeSize.y / planeSize.x ) / ( imageSize.y / imageSize.x ), 1.0 )
		);

		float offsetY = parallax != 0. ? 0. : uOffset.y;

		vec2 sliceUvs = vec2(
			uv.x * ratio.x + ( 1.0 - ratio.x ) * 0.5,
			( uv.y + offsetY ) * ratio.y + ( 1.0 - ratio.y ) * 0.5
		);
		return sliceUvs;
	}

	void main()	{
		vec2 imageUV = preserveAspectRatioSlice( vUv, uMeshScale, uImageRes );
		vec2 imageUVScaled = imageUV / uImageScale + (( uImageScale - 1. ) / 2. / uImageScale );

		vec4 c = texture2D( map, imageUVScaled );
		gl_FragColor = vec4( c.r, c.g, c.b, c.a * opacity );
	}`,
);

extend({ LayerMaterial });
