import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { getContractAbi, isSupportedNetworkName } from 'src/base/utils';
import { getContractAddresses } from 'src/base/utils';

// TODO: update contractName to CONTRACT_NAMES (narrow it down)
const useContract = ( provider, networkName : "ethereum" | "mumbai", contractName: string, readOnly = false ) => {
	const [contract, setContract] = useState( null );

	useEffect( () => {
		async function initContract() {
			if ( provider && networkName ) {
				const networkIsSupported = isSupportedNetworkName( networkName );
				if ( !networkIsSupported ) {
					console.log( 'Unsupported networkName: ', networkName );
					return;
				}
				const signer = provider.getSigner();
				const abi = getContractAbi( contractName );
				const contractAddress = getContractAddresses( networkName )[`${contractName}Address`];
				if ( !contractAddress ) {
					console.warn( `contract address is not defined for ${contractName} on ${networkName}. Did you forget to add them to getContractAddresses in utils.js? ` );
					return;
				}
				const providerOrSigner = readOnly ? provider : signer;
				const contractInstance = new ethers.Contract( contractAddress, abi, providerOrSigner );
				setContract( contractInstance );
			}
			if ( provider && !contractName ) {
				console.warn( 'contractName is not defined' );
			}
		}

		initContract();
	}, [provider, networkName, contractName, readOnly]);

	return contract;
};


export default useContract;
