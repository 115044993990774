import { useState, useEffect } from 'react';
import { Contract } from 'ethers';

// TODO: do this on API side instead

const useCheckTokensUsed = ( contract: Contract, pieceId: number, erc721Balances ) => {
	const [tokensUsed, setTokensUsed] = useState([]);
	const [loading, setLoading] = useState( true );
	const [error, setError] = useState( null );
	useEffect( () => {
		const fetchTokensUsed = async () => {
			try {
				// Check if MetaMask is unlocked
				if (typeof (window as any).ethereum !== 'undefined' && (window as any).ethereum.selectedAddress !== null ) {
					if ( !contract || !erc721Balances || erc721Balances.length === 0 ) {
						console.log( 'no contract or erc721Balances' );
						setLoading( false );
						return;
					}
					const fetchedTokensUsed = await Promise.all(
						erc721Balances.map( async ( balanceObj ) => {
							const { name, tokenIds, address: raycOrZaycAddress } = balanceObj;
							const tokensUsedForName = await Promise.all(
								tokenIds.map( async ( tokenId ) => {
									const used = await contract.tokensUsed( raycOrZaycAddress, pieceId, tokenId );
									return { tokenId, used };
								}),
							);

							return { name, tokensUsed: tokensUsedForName };
						}),
					);
					setTokensUsed( fetchedTokensUsed );
					setLoading( false );
					setError( null );
				} else {
					console.log( 'useCheckTokensUsed:: MetaMask is locked!' );
				}
			} catch ( err ) {
				setError( err );
				setLoading( false );
			}
		};

		fetchTokensUsed();
	}, [contract, erc721Balances, pieceId]);


	return { tokensUsed, loading, error };
};

export default useCheckTokensUsed;
