import { useState, useEffect, useMemo } from 'react';

// others
import { CONTRACT_NAMES, LORE_PIECE_STATUSES, SALE_STATUSES } from 'src/base/constants';
import { tokensUsedToTokensUsage, generatePieceInfoItemContents, formatDisplayPrice, tokensUsageToTierToUse, getMaxMintCount, getMintParams } from './utils';
import { abbreviateAddress } from 'src/base/utils';
import { isSupportedNetworkName } from 'src/base/utils';

// hooks
import useWallet from 'src/hooks/useWallet';
import useCheckDelegations from 'src/hooks/useCheckDelegations';
import useNetwork from 'src/hooks/useNetwork';
import useGetPieceInfo from 'src/hooks/useGetPieceInfo';
import useProvider from 'src/hooks/useProvider';
import useContract from 'src/hooks/useContract';
import useERC721Balances from 'src/hooks/useERC721Balances';
import useCheckTokensUsed from 'src/hooks/useCheckTokensUsed';

export default function usePieceSetup({ chapterId, pieceId }) {
	const [mintCount, setMintCount] = useState( 1 );
	const [connect, address] = useWallet();
	const [showLoadingOverlay, setShowLoadingOverLay] = useState( true );
	const [customLoadingMessage, setCustomLoadingMessage] = useState( null );
	const [copiesOwned, setCopiesOwned] = useState( null );
	const [displayMessageMap, setDisplayMessageMap] = useState({});

	// TODO: make DRY since this block is used in useYourCollectionData too
	// start of active address check. couldn't use useActiveAddress as it was buggy //
	const [activeAddress, setActiveAddress] = useState( null );
	const { selectedVault } = useCheckDelegations( address );
	useEffect( () => {
		if ( address === null ) return;
		if ( selectedVault !== null && selectedVault ) setActiveAddress( selectedVault );
		else setActiveAddress( address );
	}, [address, selectedVault]);
	// end of active address check //

	const { network, switchToCorrectNetwork } = useNetwork();

	// TODO: this should be hosted at a higher level, like at the app level or layout level
	useEffect( () => {
		const alreadyDisplayed = displayMessageMap.wrongNetwork;
		const isWrongNetwork = network && !isSupportedNetworkName( network );
		if ( isWrongNetwork && !alreadyDisplayed ) {
			alert( 'Unsupported network. Please switch to Ethereum' );
			setDisplayMessageMap({ wrongNetwork: true });
			switchToCorrectNetwork();
		}
	}, [displayMessageMap, network, switchToCorrectNetwork]);
	const provider = useProvider();

	const lorePieceInfo = useGetPieceInfo( network, chapterId, pieceId, address );

	const contractReadOnly = useMemo( () => !address, [address]);

	// TODO: make getting balances DRYer since it is now used in many places
	const raycContract = useContract( provider, network, CONTRACT_NAMES.RAYC, contractReadOnly );
	const zaycContract = useContract( provider, network, CONTRACT_NAMES.ZAYC, contractReadOnly );
	const loreContract = useContract( provider, network, CONTRACT_NAMES.LORE, contractReadOnly );

	const rayzZaycContractObjects = useMemo( () => {
		return [
			{
				name: CONTRACT_NAMES.RAYC,
				contract: raycContract,
			},
			{
				name: CONTRACT_NAMES.ZAYC,
				contract: zaycContract,
			},
		];
	}, [raycContract, zaycContract]);
	const { balances } = useERC721Balances( activeAddress, rayzZaycContractObjects );
	// const { balances: loreBalances } = useERC1155Balances( activeAddress, loreContract, [pieceId]);

	const isHolder = useMemo( () => {
		if ( !balances ) return false;
		if ( balances.find( x => x.name === 'rayc' )?.tokenIds.length > 0 || balances.find( x => x.name === 'zayc' )?.tokenIds.length > 0 ) return true;
		return false;
	}, [balances]);


	const { tokensUsed, loading: tokensUsedLoading } = useCheckTokensUsed( loreContract, pieceId, balances );

	const tokensUsage = useMemo( () => {
		return tokensUsedToTokensUsage( tokensUsed );
	}, [tokensUsed]);

	const tierToUse = useMemo( () => tokensUsageToTierToUse( tokensUsage ), [tokensUsage]);

	const maxMintCount = useMemo( () => getMaxMintCount( tokensUsage, tierToUse ), [tokensUsage, tierToUse]);

	useEffect( () => {
		async function getCopiesOwned() {
			if ( !pieceId ) return 0;
			if ( !activeAddress ) return 0;
			const copiesOwned = loreContract ? await loreContract.balanceOf( activeAddress, pieceId ) : null;
			setCopiesOwned( copiesOwned?.toNumber() );
		}
		getCopiesOwned();
	}, [activeAddress, loreContract, pieceId]);

	useEffect( () => {
		if ( !address && lorePieceInfo !== null ) setShowLoadingOverLay( false );
		if ( address && !tokensUsedLoading ) setShowLoadingOverLay( false );
	}, [lorePieceInfo, address, tokensUsage]);

	const tokenConfig = lorePieceInfo?.tokenConfig;
	const totalSupply = lorePieceInfo?.totalSupply;

	const tokenConfigNotYetSet = tokenConfig?.startDate === 0;
	const hasDelegationButNoAllocation = selectedVault && ( tierToUse === 'public' );

	const isLimited = tokenConfig?.isLimited ?? false;
	const remainingCount = tokenConfig?.limit - totalSupply;

	const pieceMetadata = useMemo( () => lorePieceInfo?.metadata, [lorePieceInfo?.metadata]);

	const showRevealed = true; // hardcoded

	const isPieceNotFound = pieceMetadata === undefined;
	const isUnreleased = pieceMetadata && pieceMetadata.status && pieceMetadata.status === LORE_PIECE_STATUSES.UNRELEASED;

	const saleStatus = useMemo( () => {
		if ( tokenConfigNotYetSet ) return SALE_STATUSES.NOT_STARTED;
		const now = Date.now() / 1000; // to unix timestamp
		if ( now < tokenConfig?.startDate ) return SALE_STATUSES.NOT_STARTED;
		if ( now > tokenConfig?.endDate ) return SALE_STATUSES.ENDED;
		return SALE_STATUSES.ACTIVE;
	}, [tokenConfigNotYetSet, tokenConfig?.endDate, tokenConfig?.startDate]);

	const saleHasStarted = saleStatus === SALE_STATUSES.ACTIVE;
	const saleHasEnded = saleStatus === SALE_STATUSES.ENDED;


	const { quantityItem, datesItem, criteriaItem, storyItem, costItem } = useMemo( () => {
		return generatePieceInfoItemContents({ tokenConfigNotYetSet, tokenConfig, totalSupply, remainingCount, showRevealed, pieceMetadata, saleHasEnded, saleHasStarted, isLimited });
	}, [tokenConfigNotYetSet, tokenConfig, totalSupply, remainingCount, showRevealed, pieceMetadata, saleHasEnded]);

	const infoItemsData = { quantityItem, datesItem, criteriaItem, storyItem, costItem };

	const priceToUse = useMemo( () => {
		if ( !tierToUse ) return undefined;
		if ( tierToUse === 'rayc' ) return tokenConfig?.priceRayc;
		if ( tierToUse === 'zayc' ) return tokenConfig?.priceZayc;
		if ( tierToUse === 'public' ) return tokenConfig?.pricePublic;
		return undefined;
	}, [tierToUse, tokenConfig?.priceRayc, tokenConfig?.priceZayc, tokenConfig?.pricePublic]);

	const raycAddress = useMemo( () => {
		return raycContract?.address;
	}, [raycContract]);

	const zaycAddress = useMemo( () => {
		return zaycContract?.address;
	}, [zaycContract]);

	const { raycOrZaycAddress, tokenIdToUse, tokenIdsToUse } = useMemo( () => getMintParams({ tierToUse, tokensUsage, raycAddress, zaycAddress }), [tierToUse, tokensUsage, raycAddress, zaycAddress]);


	const userIsPublicAndTokenIsNotAvailableForPublic = useMemo( () => {
		return !isHolder && !tokenConfig?.openToPublic;
	}, [isHolder, tokenConfig?.openToPublic]);

	const userIsHolderAndHasUsedAllTokensButPieceIsNotAvailableToPublic = useMemo( () => {
		if ( !tierToUse ) return false;
		const tokenIsNotOpenToPublic = tokenConfig?.openToPublic === false;
		if ( isHolder && tierToUse === 'public' && tokenIsNotOpenToPublic ) return true;
		return false;
	}, [tierToUse, isHolder, tokenConfig?.openToPublic]);

	const tierStatement = useMemo( () => {
		if ( saleStatus !== SALE_STATUSES.ACTIVE ) return undefined;
		if ( !tierToUse ) return undefined;

		if ( tierToUse === 'rayc' ) return `Mint ${mintCount} piece(s) as a Rare Apepe holder. Total mint cost: ${formatDisplayPrice( mintCount * tokenConfig?.priceRayc )}`;
		if ( tierToUse === 'zayc' ) return `Mint ${mintCount} as a Zombie Apepe holder. Total mint cost: ${formatDisplayPrice( mintCount * tokenConfig?.priceZayc )}`;
		if ( userIsHolderAndHasUsedAllTokensButPieceIsNotAvailableToPublic ) return 'Sorry, you have used all your tokens but this mint is not available to the public.';
		if ( userIsPublicAndTokenIsNotAvailableForPublic ) return `Sorry, this mint is not available to the public. Your connected address ${abbreviateAddress( activeAddress )} has no Rare Apepe or Zombie Apepe tokens.`;
		if ( tierToUse === 'public' ) return `Mint as public ${hasDelegationButNoAllocation ? '(mints to your hot wallet)' : ''}`;
		return undefined;
	}, [tierToUse, userIsPublicAndTokenIsNotAvailableForPublic, activeAddress, userIsHolderAndHasUsedAllTokensButPieceIsNotAvailableToPublic, hasDelegationButNoAllocation, saleStatus, mintCount, tokenConfig?.priceRayc, tokenConfig?.priceZayc]);

	return ({
		userIsHolderAndHasUsedAllTokensButPieceIsNotAvailableToPublic,
		userIsPublicAndTokenIsNotAvailableForPublic,
		tokenConfigNotYetSet,
		priceToUse,
		raycOrZaycAddress,
		selectedVault,
		setCustomLoadingMessage,
		showLoadingOverlay,
		setShowLoadingOverLay,
		loreContract,
		tierToUse,
		mintCount,
		tokenIdToUse,
		tokenIdsToUse,
		saleStatus,
		network,
		customLoadingMessage,
		showRevealed,
		pieceMetadata,
		tierStatement,
		copiesOwned,
		address,
		tokensUsage,
		connect,
		infoItemsData,
		setMintCount,
		maxMintCount,
		isPieceNotFound,
		isUnreleased,
	});
}
