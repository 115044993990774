import { useState, useEffect } from 'react';

// returns only the balance quantity and not the token ids

const useERC721SimpleBalances = ( address, contractObjects, triggerVariable ) => {
	const [balances, setBalances] = useState([]);
	const [loading, setLoading] = useState( true );
	const [error, setError] = useState( null );



	if ( contractObjects && !Array.isArray( contractObjects ) ) {
		throw new Error( 'useERC721SimpleBalances:: contractObjects must be an array' );
	}

	if ( !contractObjects ) {
		console.warn( 'useERC721SimpleBalances:: contractObjects is falsy' );
	}

	useEffect( () => {
		if ( !address ) {
			return;
		}
		const fetchBalances = async () => {
			try {
				// Check if MetaMask is unlocked
				if (
					typeof window.ethereum !== 'undefined' &&
          window.ethereum.selectedAddress !== null
				) {
					if (
						!address ||
            !contractObjects ||
            contractObjects.length === 0
					) {
						setLoading( false );
						return;
					}

					const fetchedBalances = await Promise.all(
						contractObjects.map( async ( contractObj ) => {
							const { name, contract } = contractObj;
							if ( !contract ) {
								console.log( 'No contract' );
								return { name, balance: 0, address: null };
							}
							const balance = await contract.balanceOf( address );
							const balanceAsNumber = balance.toNumber();
							return { name, balance: balanceAsNumber, address: contract.address };
						}),
					);

					setBalances( fetchedBalances );
					setLoading( false );
					setError( null );
				} else {
					console.log( 'useERC721SimpleBalances:: MetaMask is locked!' );
				}
			} catch ( err ) {
				console.log( 'Error in useERC721SimpleBalances' );
				setError( err );
				setLoading( false );
			}
		};

		fetchBalances();
	}, [address, contractObjects, triggerVariable]);

	if ( error ) {
		console.log( 'useERC721SimpleBalances error: ', error );
	}

	return { balances, loading, error };
};

export default useERC721SimpleBalances;
