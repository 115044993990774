import { useState, useEffect } from 'react';
import { chainIdToName } from 'src/base/utils';
import { NETWORKS } from 'src/base/constants';

// network ID to network name

// Custom hook to determine the MetaMask network. returns the network name. e.g. 'Ethereum', 'Mumbai', etc.
function useNetwork() {
	const [network, setNetwork] = useState( null );
	const [error, setError] = useState( null );

	// Function to request the user to switch to the correct network
	const switchToCorrectNetwork = async ( correctNetworkId = NETWORKS.ETH.chainId ) => {
		try {
			await window.ethereum.request({
				method: 'wallet_switchEthereumChain',
				params: [{ chainId: `0x${correctNetworkId.toString( 16 )}` }],
			});
			location.reload();
		} catch ( switchError ) {
			console.error( switchError );
		}
	};

	useEffect( () => {
		// Check if window is defined and window.ethereum is available
		if ( typeof window !== 'undefined' && window.ethereum ) {
			// eslint-disable-next-line no-inner-declarations
			async function getNetwork() {
				try {
					const networkId = await window.ethereum.request({ method: 'net_version' });
					const networkName = chainIdToName( parseInt( networkId, 10 ) );
					setNetwork( networkName );
				} catch ( err ) {
					setError( err );
				}
			}

			// Add event listener for network change
			window.ethereum.on( 'chainChanged', ( newNetworkId ) => {
				setNetwork( chainIdToName( parseInt( newNetworkId, 10 ) ) );
			});

			getNetwork();

			// Clean up event listener on component unmount
			return () => {
				if ( window.ethereum.removeListener ) {
					window.ethereum.removeListener( 'chainChanged', setNetwork );
				}
			};
		}
	}, []);

	return { network, error, switchToCorrectNetwork };
}

export default useNetwork;
