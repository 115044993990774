import { useState, useEffect } from 'react';
import { Address } from 'src/base/types';

// note: this was created before the API suported getting balances. avoid using this

const useERC721Balances = ( address: Address, contractObjects ) => {
	const [balances, setBalances] = useState([]);
	const [loading, setLoading] = useState( true );
	const [error, setError] = useState( null );

	useEffect( () => {
		const fetchBalances = async () => {
			try {				
				if ( !address || !contractObjects || contractObjects.length === 0 || contractObjects[0].contract === null ) {
					setLoading( false );
					return;
				}

				const fetchedBalances = await Promise.all(
					contractObjects.map( async ( contractObj ) => {
						if ( !contractObj ) {
							console.warn( 'contractObj is falsy' );
						}
						const { name, contract } = contractObj;
						if ( !contract ) {
							console.log( 'no contract' );
							return;
						}
						const balance = await contract.balanceOf( address );
						const balanceAsNumber = balance.toNumber();

						const tokenIds = [];
						for ( let i = 0; i < balanceAsNumber; i++ ) {
							const tokenId = await contract.tokenOfOwnerByIndex( address, i );
							tokenIds.push( tokenId.toString() );
						}

						return { name, tokenIds, address: contract.address };
					}),
				);

				setBalances( fetchedBalances );
				setLoading( false );
				setError( null );

			} catch ( err ) {
				setError( err );
			} finally {
				setLoading( false );
			}
		};

		fetchBalances();
	}, [address, contractObjects]);


	if ( error ) {
		console.log( 'useERC721Balances error: ', error );
	}

	return { balances, loading, error };
};

export default useERC721Balances;
